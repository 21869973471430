<template>
  <div class="detail-container">
    <breadcrumb-nav>
      <template v-slot:firstMenu>大转盘</template>
      <template v-slot:secondMenu>中奖详情</template>
    </breadcrumb-nav>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">中奖详情信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">中奖时间</el-col>
          <el-col class="form-border font-small" :span="18">{{rewardItem.createTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">中奖类型</el-col>
          <el-col class="form-border font-small" :span="18">{{rewardItem.rewardType | formatRewardType}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">中奖数量</el-col>
          <el-col class="form-border font-small" :span="18">{{rewardItem.rewardVal}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">中奖条件</el-col>
          <el-col class="form-border font-small" :span="18">
            <span v-if="rewardItem.rating > 0">点赞数：{{rewardItem.rating}}</span>
            <span v-if="rewardItem.showLogic === 1" style="color: red; margin: 0 10px">{{rewardItem.logicText}}</span>
            <span v-if="rewardItem.share > 0">砍刀数：{{rewardItem.share}}</span>
            <span v-if="rewardItem.rewardType == 1">无</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">当前状态</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{rewardItem.status | formatStatus}}</el-col>
        </el-row>
        <el-row v-show="rewardItem.image">
          <el-col class="form-border form-left-bg font-small" :span="6">凭证图片
          </el-col>
          <el-col class="form-border font-small" :span="18">
            <img :src="rewardItem.image" style="width: 300px" alt="">
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="rewardItem.status===5">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核时间</el-col>
          <el-col class="form-border font-small" :span="18">{{rewardItem.auditTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">{{rewardItem.auditNote || '无'}}</el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="rewardItem.status===2">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input  size="small" v-model="updateParam.auditNote" style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="rewardItem.status===2">
        <el-button type="primary" size="small" @click="handleUpdateStatus(6)">同意</el-button>
        <el-button type="danger" size="small" @click="handleUpdateStatus(5)">拒绝</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="rewardItem.status!==2">
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getRewardDetailRequest, UpdateRewardStatusRequest } from '../../../network/task'
const defaultUpdateParam = {
  status: 0,
  auditNote: '',
  rewardId: 0
}
export default {
  name: 'RedRecordDetail',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatRewardType (value) {
      switch (value) {
        case 1:
          return '金币'
        case 3:
          return '人民币'
        default:
          return '金币'
      }
    },
    formatStatus (value) {
      switch (value) {
        case 0:
          return '等待上传'
        case 1:
          return '已经发放'
        case 2:
          return '待审核'
        case 3:
          return '放弃'
        case 4:
          return '过期'
        case 5:
          return '审核决绝'
        case 7:
          return '审核通过'
        case 8:
          return '进行中'
        default:
          return '过期'
      }
    },
    formatDate (value) {
      if (value === 0) {
        return '无'
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  },
  data () {
    return {
      rewardId: 0,
      rewardItem: {},
      downloadUrlPrefix: '',
      updateParam: Object.assign({}, defaultUpdateParam)
    }
  },
  created () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.rewardId = this.$route.query.rewardId
    this.updateParam.rewardId = this.rewardId
    this.getRewardDetail()
  },
  methods: {
    getRewardDetail () {
      getRewardDetailRequest({ rewardId: this.rewardId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取奖励详情失败', 'error')
        }
        this.rewardItem = result.data
        if (this.rewardItem.icon !== '') {
          if (this.rewardItem.icon.includes('http')) {
            this.rewardItem.image = this.rewardItem.icon
          } else {
            this.rewardItem.image = this.downloadUrlPrefix + this.rewardItem.icon
          }
        }
        if (this.rewardItem.share > 0 && this.rewardItem.rating > 0) {
          this.rewardItem.showLogic = 1
        } else {
          this.rewardItem.showLogic = 0
        }
        if (this.rewardItem.logic === 0) {
          this.rewardItem.logicText = '并且'
        } else {
          this.rewardItem.logicText = '或者'
        }
        this.updateParam.auditNote = this.rewardItem.auditNote
      })
    },
    handleUpdateStatus (status) {
      this.updateParam.status = status
      this.updateParam.rewardId = parseInt(this.updateParam.rewardId)
      console.log('updateParam:', this.updateParam)
      // eslint-disable-next-line no-unreachable
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        UpdateRewardStatusRequest(this.updateParam).then(res => {
          console.log('handleUpdateStatus res:', res.data)
          this.alertMessage('操作成功', 'success')
          this.$router.back()
        })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
